<template>
  <div class="listMenu-page padding-top-layout padding-right-layout list-menu">
    <div class="list-menu-title">
      <page-title :title="titlePage" />
      <common-button v-ripple="false" class="return__header" @action="gotoListRegistered()" :label="labelBtnReturn">
        <div class="list-emission-return"
        >
          <img
            class="mr-0 return"
            src="@/assets/images/dialog/return.svg"
          />
          <img
            class="mr-0 return-active"
            src="@/assets/images/dialog/return_active.svg"
          />
        </div>
      </common-button>
    </div>
    <p v-if="isArLanguage" class="list-menu--title" dir="rtl" style="text-align:left">
      {{ $t("list_menu.description_text_1") }}
      <br />
      {{ $t("list_menu.description_text_2") }}
    </p>
    <p v-else class="list-menu--title">
      {{ $t("list_menu.description_text_1") }}
      <br />
      {{ $t("list_menu.description_text_2") }}
    </p>
    <div class="listMenus-btn">
      <div class="title-btn">
        <span>{{ $t("list_menu.title_title") }}</span>
      </div>
      <div class="listMenu-btn-text">
        <!-- <input id="title" class="input-elic mb-0" v-model="title" /> -->
        <input-field class="mt-2 mb-0" v-model="title" id="title" @blur="handleBlurInput" :errorMessages="errorMessagesTitle"  @focus="errorMessagesTitle = []" />
      </div>
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div class="title-btn title-btn-select-scope">
        <span>{{ $t("list_menu.title_scope_category") }}</span>
        <div class="title-require">{{ $t("list_menu.label_essential") }}</div>
      </div>
      <common-button
        class="listMenu-scope"
        :class="(scope_select && scope_select.scope) && 'has-scope'"
        @action="openScopePopup"
        :label ="labelScopeCategory"
        v-model="scope_select"
        id="scope_select"
        v-ripple="false"
      />
      <div class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <template>
        <div class="title-btn">
          <span>{{ $t("list_menu.title_calculation_method") }}</span>
          <div class="title-require">{{ $t("list_menu.label_essential") }}</div>
        </div>
        <div class="listMenu-btn select-methods mb-0">
          <div class="listMenu-btn-text">
            <v-select
              solo
              flat
              :label="!isShowMethod ? labelNotApplicable : placeHolderSelect"
              v-model="methods_select"
              :items="methodList"
              :class="{'disabled': methodList.length === 0}"
              :disabled="methodList.length === 0"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              class="select-type select-item list-menu--select mb-4 select-method layer1"
              id="layer1"
              @change="handleChangeMethod"
            />
            <v-select
              v-if="isShowMethod"
              solo
              flat
              :label="placeHolderSelect"
              v-model="methods2_select"
              :items="methodList2"
              :class="{'disabled': methodList2.length === 0}"
              :disabled="methodList2.length === 0"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              class="select-type select-item list-menu--select select-method layer2"
              id="layer2"
            />
          </div>
          <div v-if="isSettingPcaf && scope_select.cateId === 15" class="cat15-radio"> 
            <!-- pulldown category type -->
            <div class="listMenu-btn-text">
              <v-select
                v-if="isShowCategoryPcaf"
                solo
                flat
                :items="categoryDetailList"
                v-model="idCategorySelect"
                item-text="name" 
                item-value="id"
                :disabled="categoryDetailList.length === 0"
                :class="{'disabled': categoryDetailList.length === 0}"
                :menu-props="{ contentClass: 'select-menu export-select' }"
                class="select-type select-item list-menu--select mb-4 select-method"
                id="select-category"
                :label='$t("pcaf_update.placeholder_category_type_pcaf")'
                @change="dataTypePcafValue = null"
              ></v-select>
              <!-- pulldown data type -->
              <v-select
                v-if="isShowDataTypePcaf"
                solo
                flat
                v-model="dataTypePcafValue"
                :items="dataTypePcaf"
                :label='$t("pcaf_update.placeholder_data_type_pcaf")'
                item-text="name" 
                item-value="id"
                :disabled="dataTypePcaf.length === 0"
                :class="{'disabled': dataTypePcaf.length === 0}"
                :menu-props="{ contentClass: 'select-menu export-select' }"
                class="select-type select-item list-menu--select mb-4 select-method"
                id="select-data-type"
              ></v-select>
            </div>
            <v-radio-group v-model="attribution_factor_setting" inline class="pcaf-attribution">
              <div class="radio-part">
                <v-radio v-for="(item, i) in attributionFactors" :key="i" :label="`${item.value}`" :value="item.id" :ripple="false">
                  <template slot="label">
                    <div class="label">{{ item.value }}</div>
                    <div v-if="item.id === 1" class="helper-icon">
                      <img
                        :src="getSettingIcon('helper-icon.svg')"
                        @mouseover="changeTooltipImage($event)"
                        @mouseleave="changeTooltipImage($event, 'mouseleave')"
                        alt=""
                      />
                      <div
                        v-if="showTooltip"
                        class="helper-icon tooltip"
                        v-html='tooltipDesc'
                      ></div>
                    </div>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </div>
        </div>

        <div class="listMenu-icon" v-if="!isCsvCreate">
          <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
        </div>
      </template>

      <div class="title-btn" v-if="!isCsvCreate">
        <span>{{ $t("list_menu.title_registration_date") }}</span>
        <div class="title-require">{{ $t("list_menu.label_essential") }}</div>
      </div>
      <div class="listMenu-btn" v-if="!isCsvCreate">
        <div class="listMenu-btn-text select-year-month">
          <v-select
            solo
            flat
            :items="yearList"
            v-model="selectedYear"
            :label="labelSelectYear"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
            id="select-year"
          ></v-select>
          <v-select
            solo
            flat
            :items="monthList"
            v-model="selectedMonth"
            :label="labelSelectMonth"
            class="select-type list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
            id="select-month"
          ></v-select>
        </div>
      </div>
      <div v-if="isCsvCreate" class="listMenu-icon">
        <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
      </div>
      <div v-if="isCsvCreate" class="title-btn">
        <span>{{ $t("list_menu.title_csv_file") }}</span>
        <div class="title-require">{{ $t("list_menu.label_essential") }}</div>
      </div>
      <div class="csv-file">
        <div v-if="isCsvCreate" class="csv-input"
          v-on:dragenter="dragEnter"
          v-on:dragover="dragOver"
          v-on:dragleave="dragLeave"
          v-on:drop="dropEvent">
          <div class="import-csv">
            <img :src="getIcon('upload')" alt="" class="upload-icon" />
            <div class="csv-input-wrapper">
              <input type="file" accept=".csv" id="csv" ref="csvInput" class="d-none" @change="handleAddFile"> 
              <div class="csv-input-text">{{ $t("list_menu.description_drag_and_drop_the_file_here") }}</div>
            </div>
          </div>
          <common-button
            class="button-import"
            :label="labelBtnSelectFile"
            type="colored"
            @action="$refs.csvInput.click()"
            v-ripple="false"
            id="import-csv"
          />
        </div>
        <div v-if="isCsvCreate && csvFilename !== ''" class="csv-filename">
          <span class="csv-filename-detail">{{ csvFilename }}</span>
          <div class="remove-icon">
            <img class="remove-icon-detail" :src="getIcon('remove')" alt=""  @click="handleDeleteFile" /> 
          </div>
        </div>
      </div>
      <div class="csv-download">
        <div class="des-template-csv">
          <div class="csv-description" v-if="isCsvCreate">{{ $t("list_menu.label_maximum_upload_size") }}</div>
          <p id="download-csv" class="csv-description-download mb-0 mr-0" v-if="isCsvCreate && methodList.length === 0 && labelScopeCategory !== $t('register_data.button_please_select')" @click="handleDownloadCsvSample">{{ $t("list_menu.hyperlink_dowload_CSV") }}<span class="underline"></span></p>
          <p
            id="download-csv"
            class="csv-description-download mb-0 mr-0" 
            v-if="isCsvCreate && ((methods_select !== '' && methodList2.length === 0) || (methodList2.length > 0 && methods2_select !== '')) && labelScopeCategory !== $t('list_menu.placeholder_please_select') && !isValidPcafSelect" 
            @click="handleDownloadCsvSample"
          >
            {{ $t('list_menu.hyperlink_dowload_CSV') }}
            <span class="underline"></span>
          </p>
        </div>

        <FormatExportText v-if="isCsvCreate" :isImportData="true" @getUserEncoding="updateUserEncoding"/>
      </div>
      
      <scope-category-popup
        :dialog="isPopup"
        @close="closePopup"
        @selectedScope="selectedScope"
      />
    </div>
    <div class="list-menu-registration">
      <common-button
        class="submit-register-btn"
        :label="labelBtnSignup"
        v-model="scope_select"
        :disabled="submitRegisterHandler()"
        type="colored"
        @action="redirectToNewRegisterData()"
        v-ripple="false"
        id="register-data"
        :loading="loading"
      />
    </div>
    <div ref="scrollElement">
      <div class="return__button-responsive" :class="{'fixed-button-bottom': isFixedButton}">
        <common-button v-ripple="false" class="return__header-responsive" @action="gotoListRegistered()" :label="labelBtnReturn">
          <div class="list-emission-return-responsive"
          >
            <img
              class="mr-0 return"
              src="@/assets/images/dialog/return.svg"
            />
            <img
              class="mr-0 return-active"
              src="@/assets/images/dialog/return_active.svg"
            />
          </div>
        </common-button>
        <div class="registration-responsive">
          <common-button
            class="submit-register-btn"
            :label="$t('list_menu.button_sign_up')"
            v-model="scope_select"
            :disabled="submitRegisterHandler()"
            type="colored"
            @action="redirectToNewRegisterData()"
            v-ripple="false"
            id="register-data"
          />
        </div>
      </div>
    </div>
   
    <notification-popup :dialog="dialogNotification" :message="messageNotification" @submit="dialogNotification = false"/>
    <ErrorPopup :dialog="dialogCsv" isErrorImg :message="messageNotification" @submit="handleClosePopupCsv"/>
    <ErrorPopup v-if="limit !== messageLimit" :dialog="dialogLimit" isErrorImg :message="messageLimit" @submit="dialogLimit = false"/>
    <notification-popup v-if="limit === messageLimit" :dialog="dialogLimit" :message="messageLimit" @submit="dialogLimit = false"/>
    <ErrorPopup :dialog="dialogImportCsvBatch" :message="messageImportCsvBatch" @submit="dialogImportCsvBatch = false"/>

  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { ROUTES } from "@/router/constants";
import { SCOPE, CATEGORY } from '@/constants/registerData'
import PageTitle from "@/components/pageTitle/pageTitle";
import CommonButton from "@/components/utils/button.vue";
import { getStartMonth } from "@/api/duration";
import ScopeCategoryPopup from "@/components/dialogs/scope-category-popup";
import NotificationPopup from '@/components/dialogs/notification-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import chardet from 'chardet';
import { scopeCategoriesData, methodGhg,oldmethod, newPcafMethod } from '@/constants/export-report';
import { getExportTemplateCsv } from "@/api/export-report";
import moment from "moment";
import { getMonthListCustome, calcYearFromStartMonthCustome } from '@/utils/registerData';
import { getRangeFiscalYear } from '@/utils/fiscalyear'
import InputField from '@/components/products/input/InputField.vue';
import { saveCsvDataAction } from '@/api/newRegisterData'
import { updateLastAction } from "@/api/auth";
import { DATA_TYPE_PCAF, PCAF_CATEGORY_TYPE_METHOD_1, PCAF_CATEGORY_TYPE_METHOD_2 } from '@/constants/pcaf'
import { getDataTypeApi } from '@/api/pcaf'
import FormatExportText from '@/components/csvFormat/export-text';
export default {
  name      : "EmissionHomeRegistration",
  components: {
    PageTitle,
    CommonButton,
    ScopeCategoryPopup,
    NotificationPopup,
    ErrorPopup,
    InputField,
    FormatExportText
  },
  data() {
    return {
      breadcrumb             : [
        {
          text    : this.$t('list_menu.hyperlink_home'),
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : this.$t('list_menu.hyperlink_registration_of_emissions'),
          disabled: false,
          href    : ROUTES.EMISSIONS
        },
        {
          text    : this.$t('list_menu.label_new_registration_of_emissions'),
          disabled: true,
          href    : ROUTES.EMISSIONS
        }
      ],
      method                 : null,
      labelScopeCategory     : this.$t("list_menu.placeholder_please_select"),
      currentSelectedLayer2  : null,
      isHidden               : false,
      radioDialog            : false,
      radioDialogCategory    : false,
      radioType              : this.$t('list_menu.placeholder_please_select'),
      dialogCancelBtn        : false,
      currentSelected        : 1,
      currentSelectedCategory: 1,
      items                  : [{
        text : "申請状況確認",
        path : ROUTES.LIST_EMISSION_SUBMITTED,
        image: "user.svg"
      }],
      monthList           : [],
      yearList: [],
      startMonth: null,
      selectedMonth: "",
      selectedYear: "",
      listVisible            : [],
      methodList             : [],
      methodList2            : [],
      title                  : "",
      scope_select           : {
        cateId: "",
        scope : ""
      },
      category_select        : "",
      methods_select         : "",
      methods2_select        : "",
      date_month_select      : "",
      dataPageEdit           : false,
      dataPageTemplateList   : false,
      isPopup                : false,
      dialogNotification: false,
      dialogLimit: false,
      messageLimit: this.$t("list_menu.description_message_limit"),
      csvFilename: '',
      csvFileData: '',
      dialogCsv: false,
      categoryName: '',
      limitSize: false,
      // limit: 'このファイル形式には対応しておりません。\n.csvの拡張子のファイルをアップロードしてください。',
      listYearSetting: [],
      isFixedButton: true,
      fileCsv: null,
      errorMessagesTitle: [],
      attributionFactors: [
        {
          id: 1,
          value: this.$t("setting_pcaf.radio_attribution_factor_setting_1")
        },
        {
          id: 2,
          value: this.$t("setting_pcaf.radio_attribution_factor_setting_2")
        }
      ],
      attribution_factor_setting: 1,
      showTooltip: false,
      dataTypePcaf: [],
      idCategorySelect: null,
      categoryDetailList: [],
      dataTypePcafValue: null,
      userEncoding: null,
      loading: false,
      dialogImportCsvBatch: false,
      messageImportCsvBatch: ''
    };
  },
  async mounted() {
    await Promise.all([this.updateBreadCrumb(this.breadcrumb), this.getDuration()]);
    if(this.$route.query?.isErrorCsv) {
      const dataQuery = this.$route.query;
      this.scope_select = dataQuery.scope_select;
      this.date_month_select = parseInt(dataQuery.month);
      this.title = dataQuery.title;
      this.selectedScope(this.scope_select, dataQuery.categoryName)
      this.methods_select = dataQuery.methods;
      this.methods2_select = dataQuery.methods2;
      this.handleChangeMethod(this.methods_select);
      this.idCategorySelect = dataQuery?.categoryIdSelect ? Number(dataQuery.categoryIdSelect) : null
      this.dataTypePcafValue = dataQuery?.dataTypeIdSelect ? Number(dataQuery.dataTypeIdSelect) : null
      this.dialogCsv = true;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState("userData", ["currentUser", "isSettingPcaf", "planType", "language"]),

    messageNotification() {
      return this.$t("list_menu.description_message_notification");
    },

    limit() {
      return this.$t("list_menu.description_limit");
    },

    titlePage() {
      return this.$t("list_menu.title_new_registration_of_emissions");
    },

    placeHolderSelect() {
      return this.$t("list_menu.placeholder_please_select");
    },

    labelSelectYear () {
      return this.$t("list_menu.placeholder_select_year");
    },

    labelSelectMonth () {
      return this.$t("list_menu.placeholder_select_month");
    },

    labelBtnSelectFile () {
      return this.$t("list_menu.button_select_files");
    },

    labelBtnReturn () {
      return this.$t("list_menu.button_return_to_the_registration_list");
    },

    labelBtnSignup () {
      return this.$t("list_menu.button_sign_up");
    },

    isCsvCreate() {
      return this.$route.query?.csv === 'true' ? true : false;
    },

    isShowMethod() {
			if (this.isSettingPcaf && this.scope_select.cateId === 15) {
				return false;
			}

      if (!this.$route.query?.csv) return true

      if(!this.scope_select?.scope ) return true

      if(this.scope_select && this.methodList.length === 0) return false

      return true
    },
    getYearSettingPcaf() {
      return this.listYearSetting.filter(item => item.isSettingPcaf)?.map(item => item.year)
    },
    getYearSettingGhg() {
      return this.listYearSetting.filter(item => !item.isSettingPcaf)?.map(item => item.year)
    },
    labelNotApplicable() {
      return this.isSettingPcaf && this.scope_select.cateId === 15 ? this.$t("pcaf_update.placeholder_data_set_pcaf") : this.$t("list_menu.label_not_applicable"); 
    },
    tooltipDesc() {
      return this.$t("setting_pcaf.tooltip_attribution_factor_setting");
    },
    isArLanguage() {
      return this.language.code === 'ar-AE';
    },
    isShowCategoryPcaf() {
      return this.isSettingPcaf && this.scope_select?.cateId === 15
    },
    isShowDataTypePcaf() {
      return this.isSettingPcaf && this.scope_select?.cateId === 15
    },
    isValidPcafSelect() {
      if(this.isSettingPcaf && this.scope_select?.cateId === 15) {
        return !this.idCategorySelect || this.categoryDetailList.length ? !this.dataTypePcafValue : true
      }
      return false
    }
  },
  watch   : {
    methods_select(value) {
      this.methodList2 = [];
      if (value === this.$t('register_data.radio_transportation')) {
        this.methodList2 = [
          this.$t('register_data.radio_fuel_and_shipping'), 
          this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
          this.$t('register_data.radio_calculated_by_the_tkm_method')
        ];
      }
      if (value === this.$t('register_data.radio_base')) {
        this.methodList2 = [
          this.$t('register_data.radio_fuel'), 
          this.$t('register_data.radio_electricity')
        ];
      }
      if (value === this.$t('register_data.radio_calculated_direct_use_stage')) {
        this.methodList2 = [
          this.$t('register_data.radio_calculated_engery_using_product'),
          this.$t('register_data.radio_calculated_fuel_feedstock'),
          this.$t('register_data.radio_calculated_containing_product')
        ];
      }
      
      this.methods2_select = this.$route.query?.methods2 || ""
    },
    scope_select(newVal) {
      this.methods_select = this.$route.query?.methods || ""
      this.methods2_select = this.$route.query?.methods2 || ""
      const categorySelected = newVal?.cateId
      if([4,6,7,9].includes(categorySelected) && this.$route.query?.isErrorCsv !== 'true') {
        this.methodList2 = [];
      }
    },
    // selectedMonth(value) {
    //   this.yearList = calcYearFromStartMonthCustome(this.startMonth, this.selectedYear || 2017);
    // },
    selectedYear(value) {
      this.monthList = getMonthListCustome(value, this.startMonth);
      if(this.selectedMonth && this.monthList.findIndex(item => item.value === this.selectedMonth) < 0) {
        this.selectedMonth = ''
      }
    }
  },

  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb"]),
    ...mapActions("registerData", ["updateDataMonth", "updateDuration", "updateYearSelected"]),
    ...mapActions("newRegisterData", ["updateMethodLayer1Id", "updateMethodLayer2Id", "updatePcafDetail"]),
    ...mapActions("userData", ["updateMethodSetting",
    ]),
    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement.offsetTop; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
      if (scrollPosition > (scrollElementTop + 295)) { //position btn currently
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },
    handleBlurInput(value) {
      if (value.length > 255) {
        this.errorMessagesTitle.push(this.$t('validation.error_maximum_20_charactors', { charactors : '255'}));
      }
    },
    gotoListRegistered(){
      return this.$router.push({
        path: `${ROUTES.EMISSIONS}`
      });
    },
    async redirectToNewRegisterData() {
      sessionStorage.setItem("title", this.title);
      sessionStorage.setItem("duration_id", this.selectedDuration);
      sessionStorage.setItem("pattern_id", this.methods_select);
      sessionStorage.setItem("attribution_factor_setting", this.attribution_factor_setting);
      // sessionStorage.setItem("csvImportEvent", this.csvFileData);
      if(this.isSettingPcaf) { //save categoryIdSelect, dataTypeIdSelect if has plan pcaf
        const dataPcafDetail = {
          categoryIdSelect: this.idCategorySelect,
          dataTypeIdSelect: this.dataTypePcafValue
        }
        const dataPcafStore = {}
        dataPcafStore[this.$store.state.userData.contractor] = dataPcafDetail
        this.updatePcafDetail(dataPcafStore)
      }
      
      await Promise.all([
        this.updateDuration(this.selectedDuration),
        this.updateDataMonth(moment(this.selectedMonth, 'M').format('M')),
        this.updateYearSelected(moment(this.selectedYear, 'YYYY').format('YYYY')),
        this.getMethodsId(),
        
      ]);
      if(this.isCsvCreate) {
        this.loading = true;
        const paramsImportCsv = this.getDataPramsImportCSV();
        try {
          const res = await saveCsvDataAction({ ...paramsImportCsv, contractor_id: this.currentUser.user?.contractor_id, title: this.title ?? null}, this.fileCsv);
          sessionStorage.setItem("messageImportCsvOk", res?.message);          
          try {
            const response = await updateLastAction({ contractor_id: this.currentUser.user.contractor_id });
            localStorage.setItem('hasGroupsImporting', response.groups_importing.length ? true : false)
          } catch (error) {
            console.error(error);
          }
          this.$router.push({ path: ROUTES.EMISSIONS }).catch(() => {
          });
          this.loading = false;
        } catch (error) {
          // Show pop error
          this.dialogImportCsvBatch = true;
          this.messageImportCsvBatch = error?.errors?.join('\n') ?? this.$t("list_menu.description_message_notification");
          this.loading = false;
        }
      } else {
        return this.$router.push({
          path: `${ROUTES.EMISSIONS}/${ROUTES.REGISTER}/${this.scope_select.scope}/${this.scope_select.cateId}`,
          query: { import: true }
        });
      }
    },
    selectedScope(getScopesAndCategories, categoryName) {
      this.categoryName = categoryName;
      this.labelScopeCategory = this.getLabelScopeCategory(getScopesAndCategories, categoryName);
      this.scope_select       = getScopesAndCategories;
      //check scope, category
      if (this.scope_select.scope === 1) {
        this.methodList = [
          this.$t('register_data.radio_calculated_activities_other_than_transportation'),
          this.$t('register_data.radio_calculated_by_fuel_method_scope1'), 
          this.$t('register_data.radio_calculated_by_fuel_efficiency_method_scope1'),
          this.$t('register_data.radio_calculated_by_the_tkm_method_scope1'), 
        ];
        this.methodList2 = [];
      }
      if ([2, 4].includes(this.scope_select.scope)) {
        this.methodList  = [];
        this.methodList2 = [];
      }
      if (this.scope_select.scope === 3) {
        if ([1, 2, 3, 5, 8, 10, 12, 13, 14, 16].includes(this.scope_select.cateId)) {
          this.methodList  = [];
          this.methodList2 = [];
        }
        if (this.scope_select.cateId === 4) {
          this.methodList = [
            this.$t('register_data.radio_transportation'),
            this.$t('register_data.radio_base'),
            this.$t('register_data.radio_shipper_responsibility_report')
          ];
          if (this.methods_select === this.$t('register_data.radio_transportation')) {
            this.methodList2 = [
              this.$t('register_data.radio_fuel_and_shipping'),
              this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
              this.$t('register_data.radio_calculated_by_the_tkm_method')
            ];
          }
          if (this.methods_select === this.$t('register_data.radio_base')) {
            this.methodList2 = [
              this.$t('register_data.radio_fuel'),
              this.$t('register_data.radio_electricity')
            ];
          }
        }
        if (this.scope_select.cateId === 6) {
          this.methodList = [
            this.$t('register_data.radio_calculated_by_fuel_method'),
            this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
            this.$t('register_data.radio_calculated_passenger_kilometer'),
            this.$t('register_data.radio_calculated_payment_amount'),  //s3c6 old
            this.$t('register_data.radio_calculated_number_of_accommodation'),
            this.$t('register_data.radio_calculated_number_business_trip_day'),
            this.$t('register_data.radio_calculated_number_of_employees')
          ];
        }
        if (this.scope_select.cateId === 7) {
          this.methodList = [
            this.$t('register_data.radio_calculated_by_fuel_method'),
            this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
            this.$t('register_data.radio_calculated_passenger_kilometer'),
            this.$t('register_data.radio_calculated_payment_amount'),
            this.$t('register_data.radio_calculated_from_the_number_of_employees_and_business_days')
          ];
        }
        if (this.scope_select.cateId === 15) {
					if (this.isSettingPcaf) {
            this.methodList = [
              this.$t('setting_pcaf.pulldown_pcaf_method_listed_stocks_bouds'),
              this.$t('setting_pcaf.pulldown_pcaf_method_bussiness_loans_unlisted_stocks'),
              this.$t('setting_pcaf.pulldown_pcaf_method_project_finance'),
              this.$t('setting_pcaf.pulldown_pcaf_method_commercial_estate'),
              this.$t('setting_pcaf.pulldown_pcaf_method_home_loan'),
              this.$t('setting_pcaf.pulldown_pcaf_method_car_loan'),
              this.$t('setting_pcaf.pulldown_pcaf_method_debt')
            ];
            // this.handleGetDataTypePcaf()
          } else {
            this.methodList = [
              this.$t('register_data.radio_stock'),
              this.$t('register_data.radio_debt_bond_loan'),
              this.$t('register_data.radio_project_finance'),
              this.$t('register_data.radio_asset_management_customer_service'),
            ];
          }
          this.methodList2 = [];
          this.categoryDetailList = []
          this.dataTypePcaf = []
        }
        if (this.scope_select.cateId === 9) {
          this.methodList = [
            this.$t('register_data.radio_transportation'),
            this.$t('register_data.radio_base')
          ];
          if (this.methods_select === this.$t('register_data.radio_transportation')) {
            this.methodList2 = [
              this.$t('register_data.radio_fuel_and_shipping'),
              this.$t('register_data.radio_calculated_by_fuel_efficiency_method'),
              this.$t('register_data.radio_calculated_by_the_tkm_method'),
            ];
          }
          if (this.methods_select === this.$t('register_data.radio_base')) {
            this.methodList2 = [
              this.$t('register_data.radio_fuel'),
              this.$t('register_data.radio_electricity')
            ];
          }
        }
        if (this.scope_select.cateId === 11) {
          this.methodList = [
            this.$t('register_data.radio_calculated_direct_use_stage'),
            this.$t('register_data.radio_calculated_indirect_use_stage')
          ];
          if (this.methods_select === this.$t('register_data.radio_calculated_direct_use_stage')) {
            this.methodList2 = [
              this.$t('register_data.radio_calculated_engery_using_product'),
              this.$t('register_data.radio_calculated_fuel_feedstock'),
              this.$t('register_data.radio_calculated_containing_product')
            ];
          }
        }
      }


      this.isPopup = false;
    },
    getLabelScopeCategory(scopeAndCategory, categoryName) {
    if (scopeAndCategory.scope > SCOPE.SCOPE_2) {
      if (scopeAndCategory.cateId === CATEGORY.CATEGORY_16) {
        return `${this.$t('dashboard_main.label_scope')} ${scopeAndCategory.scope} > ` + categoryName;
      } else if (scopeAndCategory.scope === SCOPE.SCOPE_4) {
        return `${ this.$t('register_data.pulldown_others')} > ` + categoryName;
      } else {
        return (
          `${this.$t('dashboard_main.label_scope')} ${scopeAndCategory.scope} > ${scopeAndCategory.cateId}. ` +
          categoryName
        );
      }
    } else {
      return `${this.$t('dashboard_main.label_scope')} ${scopeAndCategory.scope} > ` + categoryName;
    }
  },
    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },
    async getDuration() {
      // this.monthList = getMonthList();
      await getStartMonth(this.$store.state.userData.contractor).then((res) => {
        this.startMonth = res.data.start_month;
        this.yearList = calcYearFromStartMonthCustome(res.data.start_month, 2017);
        this.monthList = getMonthListCustome(null, res.data.start_month);
      });
    },
    openScopePopup() {
      this.isPopup = true;
    },
    closePopup() {
      this.isPopup = false;
    },
    submitRegisterHandler() {
      const methodIndex = this.methodList?.findIndex(item => item === this.methods_select)
      const isMethodHasMultiCategory = [0,1].includes(methodIndex)
      return ((this.selectedYear === "" && !this.isCsvCreate) ||
        (this.selectedMonth === "" && !this.isCsvCreate) ||
        this.scope_select.cateId === "" ||
        this.scope_select.scope === "" ||
        this.errorMessagesTitle.length > 0 ||
        (this.methodList.length > 0 && this.methods_select === "") ||
        (this.methodList2.length > 0 && this.methods2_select === "") ||
        (this.isCsvCreate && !this.csvFileData) || 
        (this.categoryDetailList.length && this.isShowCategoryPcaf && (!this.idCategorySelect || !this.dataTypePcafValue && isMethodHasMultiCategory)) ||
        (this.isShowDataTypePcaf && !this.dataTypePcafValue && !isMethodHasMultiCategory)
      );
    },
    getMethodsId() {
      let methodLayer1Id = null;
      let methodLayer2Id = null;
      if (this.scope_select.scope === 1) {
        const indexMethod = this.methodList.findIndex(method => method === this.methods_select)
        methodLayer1Id = indexMethod + 1;
      } else if (this.scope_select.scope === 3) {
        if (this.scope_select.cateId === 4) {
          if (this.methods_select === this.$t('register_data.radio_transportation')) {
            methodLayer1Id = 1;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 4;
          } else if (this.methods_select === this.$t('register_data.radio_base')) {
            methodLayer1Id = 2;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 7;
          } else {
            methodLayer1Id = 3;
          }
        } else if ([6, 7].includes(this.scope_select.cateId)) {
          const indexMethod = this.methodList.findIndex(method => method === this.methods_select)
          methodLayer1Id = indexMethod + 1;
        } else if (this.scope_select.cateId === 9) {
          if (this.methods_select === this.$t('register_data.radio_transportation')) {
            methodLayer1Id = 1;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 1;
          }
          if (this.methods_select === this.$t('register_data.radio_base')) {
            methodLayer1Id = 2;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 4;
          }
        } else if (this.scope_select.cateId === 11) {
          if (this.methods_select === this.$t('register_data.radio_calculated_direct_use_stage')) {
            methodLayer1Id = 1;
            const indexMethod = this.methodList2.findIndex(method => method === this.methods2_select)
            methodLayer2Id = indexMethod + 1;
          } else {
            methodLayer1Id = 2;
          }
        } else if (this.scope_select.cateId === 15 && this.isSettingPcaf) {
					const indexMethod = this.methodList.findIndex(method => method === this.methods_select)
					methodLayer1Id = indexMethod + 1;
        } else if (this.scope_select.cateId === 15 && !this.isSettingPcaf) {
          const indexMethod = this.methodList.findIndex(method => method === this.methods_select)
          methodLayer1Id = indexMethod + 1;
        }
      } else {
        methodLayer1Id = 1;
      }
      this.updateMethodLayer1Id(methodLayer1Id);
      this.updateMethodLayer2Id(methodLayer2Id);
    },
    getIcon(icon) {
      return require(`@/assets/icons/csv-input/${icon}.svg`);
    },
    dragEnter(event) {
      event.preventDefault();
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragLeave() {},
    hasNonAsciiCharacters(inputText) {
      const nonAsciiRegex = /[^\x00-\x7F]/;
      return nonAsciiRegex.test(inputText);
    },
    handleAddFile(event) {
      if (event.target.files[0]) {
        this.handleValidateFile(event.target.files[0]);
        this.$refs.csvInput.value = null;
      }
    },
    async dropEvent(event) {
      event.preventDefault();
      this.handleValidateFile(event.dataTransfer.files[0]);
    },
    handleClosePopupCsv() {
      this.dialogCsv = false;
      this.$router.replace({ path: '/emissions/list-menu/?csv=true'})
    },
    handleDeleteFile() {
      this.csvFilename = '';
      this.csvFileData = '';
      this.fileCsv = null;
    },
    isDisableDownLoad() {
      return  this.scope_select.cateId === "" ||
        this.scope_select.scope === "" ||
        (this.methodList.length > 0 && this.methods_select === "") ||
        (this.methodList2.length > 0 && this.methods2_select === "")
    },
    handleDownloadCsvSample() {
      const params = this.getDataPramsImportCSV();
      //todo: getExportTemplateCsv(num, contractor)
      const row_num_pattern = params.row_num_pattern;
      getExportTemplateCsv(row_num_pattern, this.currentUser.user?.contractor_id, params.dataPcafDetail).then((res) => {
        let filename = ''
        const SCOPE3_CATEGORY16 = 45
        const SCOPE4 = 46

        if(row_num_pattern === SCOPE3_CATEGORY16) {
          filename = `${this.$t('a_export_report.label_scope_3')} 3_16-${this.$t('a_export_report.file_name_scope3_category16')}`;
        } else if(row_num_pattern === SCOPE4) {
          filename = this.$t('a_export_report.file_name_scope4');
          filename = filename.replace('. ', '-')
        } else {
          const names = this.labelScopeCategory.split(' > ')
          filename = `${names[0].trim()}_${names[1].trim()}`
          filename = filename.replace('. ', '-')
          const method2 = this.methods2_select
          const method = this.methods_select
          if(method && !method2) {
            filename += `_${method}`
          }
          if(method2) {
            filename += `_${method2}`
          }
          if (this.isSettingPcaf) {
            let dataTypeName = this.dataTypePcaf.find(i => i.id == this.dataTypePcafValue)?.name || '';
            if ([47, 48].includes(row_num_pattern)) {
              let dataTypeClassify = this.categoryDetailList.find(i => i.id == this.idCategorySelect).name;
              filename += `_${dataTypeClassify}_${dataTypeName}`;
            } else {
              filename += `_${dataTypeName}`;
            }
            
          }
        }
        if(filename.length > 190) {
          filename = filename.slice(0, 186)
        }
        const time = moment().format("YYYYMMDD");
        filename += `_${time}.csv`

        const url = window.URL.createObjectURL(
          new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getDataPramsImportCSV() {
      if (this.isDisableDownLoad()) return

      let scopeCategoriesDataPattern = scopeCategoriesData
      const indexGhg = scopeCategoriesDataPattern.findIndex(item => item.categories === 15)
      let dataPcafDetail = {}
      if (this.isSettingPcaf && this.scope_select?.cateId === 15) {
        dataPcafDetail = {
          type_data: this.dataTypePcafValue,
          ...(this.idCategorySelect && { type_classify: this.idCategorySelect }),
          is_in_approx: this.attribution_factor_setting === 1 ? 1: 0
        };
        scopeCategoriesDataPattern[indexGhg].methods = newPcafMethod
      } else {
        scopeCategoriesDataPattern[indexGhg].methods = oldmethod
      }

      let pattern = scopeCategoriesDataPattern.find(item => item.scope === parseInt(this.scope_select.scope) && item.categories === parseInt(this.scope_select.cateId))
      if (this.methods_select) {
        pattern = pattern.methods.find(method => method.text === this.methods_select)
      }
      if (this.methods2_select) {
        pattern = pattern.methods.find(method2 => method2.text === this.methods2_select)
      }

      return { row_num_pattern: pattern.row_num_pattern, dataPcafDetail: dataPcafDetail }
    },
    handleCheckInputDuration() {
      if(!this.planType?.is_pcaf) return
      const startYear = this.selectedYear
      const endYear = this.selectedYear
      const startMonth = this.selectedMonth
      const endMonth = this.selectedMonth

      if(!this.selectedMonth || !this.selectedYear) return

      this.checkPcafInDurtionSelect({startYear, endYear, startMonth, endMonth, startMonthSetting: this.startMonth})
    },
    checkPcafInDurtionSelect(dataSelected) {
      const rangeYear = getRangeFiscalYear(dataSelected)
      const isSettingPcafYear = rangeYear.filter(year => this.getYearSettingPcaf.includes(year))?.length > 0
      this.monthList = getMonthListCustome(this.selectedYear, this.startMonth);
      if ((this.monthList.find(item => item.value === this.selectedMonth) || this.selectedMonth == '') && this.scope_select?.cateId === 15) {
        if (isSettingPcafYear !== this.isSettingPcaf) {
          this.updateMethodSetting(isSettingPcafYear)
          this.methods_select = ''
          this.methodList = []
          this.methodList2 = []
          if (this.categoryName) {
            this.selectedScope(this.scope_select, this.categoryName)
          }
        }
      }
      if(!this.scope_select?.cateId) {
        this.updateMethodSetting(isSettingPcafYear)
      }
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip = true;
      }
    },
    async handleGetDataTypePcaf(methodIndex) {
      try {
        const response = await getDataTypeApi(methodIndex + 1 + 4)   // 4 is number off ghg method
        this.dataTypePcaf = response.data
      } catch (error) {
        console.warn(error);
      }
    },
    async handleChangeMethod(newVal) {
      try {
        if(!this.isSettingPcaf) return true
        this.dataTypePcaf = []
        this.idCategorySelect = null
        this.dataTypePcafValue = null
        const methodIndex = this.methodList?.findIndex(item => item === newVal)
        if(methodIndex <= 1) {
          this.categoryDetailList = methodIndex === 0 ? PCAF_CATEGORY_TYPE_METHOD_1 : PCAF_CATEGORY_TYPE_METHOD_2
        } else  this.categoryDetailList = [];
        this.handleGetDataTypePcaf(methodIndex)
      } catch (error) {
        console.warn(error);
      }
    },
    handleValidateFile(file) {
      if (file.type !== "text/csv") {
        this.limitSize = true
        this.messageLimit = this.$t('list_menu.description_limit');
        this.dialogLimit = true;
        return;
      } else if (file.size > 5242880) {
        this.limitSize = true
        this.messageLimit = this.$t('list_menu.description_message_limit');
        this.dialogLimit = true;
        return;
      } else {
        this.csvFilename = file.name;
        this.fileCsv = file;
        const reader = new FileReader();
        reader.onload = () => {
          const encoding = chardet.detect(new Uint8Array(reader.result));
          if (encoding !== this.userEncoding) {
            this.limitSize = true
            this.messageLimit = this.$t('list_menu.description_message_notification');
            this.dialogLimit = true;
            return;
          }
          const decoder = new TextDecoder(encoding);
          this.csvFileData = decoder.decode(new Uint8Array(reader.result));
        };
        reader.readAsArrayBuffer(file);
        this.$refs.csvInput.value = '';
      }
    },
    updateUserEncoding(value) {
      this.userEncoding = value === "Shift JIS" ? "Shift_JIS" : "UTF-8";
    }
  }
};
</script>
<style lang="scss">
.list-menu {
  .select-method {
    &.disabled {
      .v-input__slot {
        background: #E4E6E7!important;
      input {
        background: unset !important;
      }
    }
    }
  }
}

.listMenu-btn-text {
  .v-text-field.v-text-field--solo .v-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoMid;
  }
}
.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}
.listMenu-scope .v-btn__content {
  color: $monoMid;
  height: max-content;
  max-width: 100%
}
.listMenu-scope.has-scope  .v-btn__content {
  color: $monoBlack;
}
.listMenu-scope.has-scope:hover {
  .v-btn__content {
    color: #fff!important;
  }
} 
.listMenu-scope .v-btn__content div{
  word-break: break-word;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-type.select-item .v-select__selection {
  color: $monoBlack;
}
.select-type.select-item {
  @media (min-width: 1024px) {
    .v-select__selection {
      max-width: 440px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.select-menu {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: #F7F7F2;
  }
  &::-webkit-scrollbar-thumb {
    background: #BFD4D9;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid #f7f7f2;
    border-right: 4px solid #f7f7f2;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 180px !important;
  margin-top: 45px !important;
  .theme--light.v-list{
    padding: 0;
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: #E3EDED;
      }
    }
  }
}
.listMenu-page {
  .cat15-radio {
    .pcaf-attribution {
      .v-label {
        color: $monoBlack;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.42px;
      }
      .label {
        word-break: break-word;
        position: relative;
        max-width: 484px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.return__button-responsive {
  &.fixed-button-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    top: auto;
    padding: 16px 20px 0px 20px;
  }
}

.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}
.listMenu-page {
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;

    width: 100%;
    // height: 40px;
    .return__header {
      display: none;
    }
  }

  .list-menu--title {
    width: 100%;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */
    margin-bottom: 48px;
    letter-spacing: 0.05em;

    /* Mono/Black */

    color: $monoBlack;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .listMenus-btn {
    display: flex;
    flex-flow: column;
    padding: 0px;
    /* Background/Light */
    background: #e3eded;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      padding: 24px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 16px;
      // padding: 8px 8px 8px 12px;
      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }
      .select-year-month {
        display: flex;
        flex-flow: column !important;
        gap: 16px;
      }
      &.select-methods {
        flex-flow: column;
      }
    }

    .listMenu-scope {
      text-transform: capitalize;
      color: $monoMid;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.03em;
      margin-top: 10px;
      width: 100%;
      height: max-content !important;
      &.has-scope {
        color: $monoBlack;
      }
      &:hover {
        color: $monoWhite;
      }
    }
    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: auto;
        height: 20px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;
  }

  .title-btn-select-scope {
    margin-bottom: 12px;
  }

  .listMenus-btn {
    padding: 15px 20px;
  }

  .csv-file {
    background: #F7F7F2;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    margin-top: 10px;
  }
  .csv-input {
    background: $monoOffWhite;
    // box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    // border-radius: 4px;
    height: 252px;
    // margin-top: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
    .import-csv {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 164px;
      width: 100%;
      border: 1px dashed #C8DEEA;
      padding: 32px 8px;
      gap: 8px;
      .upload-icon {
        width: 50px;
        height: 64px;
      }
      .csv-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .csv-input-text {
          font-weight: 500;
          font-size: 15px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.05em;
          color: $monoMid;
        }
      }
    }
  }
  .csv-filename {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $dividerBlack;
    &-detail {
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoBlack;
      //display: flex;
      //flex-direction: row;
      //align-items: center;
      padding: 0px 0px 0px 16px;
      word-break: break-all;
      //gap: 8px;
      height: inherit;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .remove-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 60px;
      height: 60px;
      &-detail {
        cursor: pointer;
      }
    }
  }
  .csv-download {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: auto;
    margin-top: 8px;
  }
  .csv-description {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: $monoBlack;
  }
  .csv-description-download {
    color: $goldMid;
    text-align: center;
    cursor: pointer;

    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.75px;
    text-decoration-line: underline;
    position: relative;
    width: max-content;
    margin-inline: auto;
    .underline {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $goldMid;
      transition: width 0.3s ease;
      bottom: 2px;
    }
    &.disable {
      cursor: text;
      color: $monoBlack;
    }
    &:hover {
      text-decoration: underline;
      .underline {
        width: 100%;
      }
    }
  }
  .helper-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    right: -15px;

    img {
      z-index: 1;
      width: 20px;
      height: 20px;
      background: $bgLight;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    &.tooltip {
      display: block;
      justify-content: left;
      background: $monoWhite;
      border-radius: 4px;
      color: $monoBlack;
      margin: 0 auto;
      font-size: 15px;
      padding: 10px;
      height: unset;
      width: 300px;
      max-width: max-content;
      position: absolute;
      right: 0px;
      bottom: 20px;
      z-index: 9999;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
      0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
      0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    }
  }
}
.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    padding-top: 16px;
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}
.return__header-responsive {
  width: 90%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;
      .return-active {
        display: none;
      }
    }
  &:hover {
      .list-emission-return-responsive {
        .return {
          display: none;
        }
        .return-active {
          display: block;
        }
      }
    }
  &:active {
      .list-emission-return-responsive {
        .return {
          display: block;
        }
        .return-active {
          display: none;
        }
      }
    }
}
.registration-responsive {
  width: 90%;
  margin: auto;
  padding: 16px 0;
  .common-btn  {
    width: 100%;
  }
}

@include desktop {
  .return__button-responsive {
    display: none;
  }
  .input-elic {
    /* width: 440px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 16px;
    margin-top: 8px;
    position: static;
    width: 496px;
    height: 40px;
    left: 0;
    top: 32px;
    background: $monoWhite;
    box-sizing: border-box;
    box-shadow: inset 0 2px 2px rgb(160 181 186 / 15%), inset 0 2px 6px rgb(160 181 186 / 50%);
    border-radius: 4px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    outline: none;
  }
  .listMenu-page {
    .listMenus-btn {
      display: flex;
      // flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 32px 32px 48px;
      

      .listMenu-btn {
        // margin: 0 12px 24px 12px;
        display: flex;
        flex-flow: column;
        // align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;
          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .select-year-month {
          flex-flow: row !important;
        }
      }
    }

    .submit-register-btn {
      // background: $goldMid !important;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      border-radius: 4px;
      transition: 0.1s ease-out;
      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 50px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }
      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;
        .list-emission-return {
          height: 20px;
          .return-active {
            display: none;
          }
        }
        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }
            .return-active {
              display: block;
            }
          }
        }
        &:active {
          .list-emission-return {
            .return {
              display: block;
            }
            .return-active {
              display: none;
            }
          }
        }
      }

    }

    .list-menu--title {
      padding-left: 40px;
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }

      }
    }
    .csv-download {
      display: flex;
      flex-direction: column;
      align-items: unset;
      justify-content: space-between;
      padding: 0px;
      width: 100%;
      height: 85px;
      margin-top: 8px;
      .des-template-csv {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .helper-icon {
      position: relative;
      margin-left: 6px;
      right: unset;
      &.tooltip {
        position: absolute;
        width: 400px;
        max-width: unset;
      }
    }
  }
}

::v-deep .submit-register-btn {
  .v-btn__content {
    text-transform: capitalize !important;
  }
}

.v-application a {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .return__header,
::v-deep .return__button-responsive {
  .v-btn__content {
    div {
      text-transform: lowercase;
    }
    div:first-letter {
      text-transform: uppercase !important;
    }
  }
}
</style>
